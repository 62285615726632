<!-- eslint-disable max-len -->
<template>
  <div v-if="isShow && !isOrgSchool" class="cookies-statement">
    <div class="wrapper" :class="{'is-us-cookie': $store.state.locale === 'us'}">
      <div v-if="$store.state.locale === 'us'" class="us-description">
        <span>We improve our products and advertising by using Microsoft Clarity and other cookies to see how you use our website. By using our site, you agree that we and our service provider, including but not limited to Google and Microsoft, can collect and use this data. Our <a href="/privacy-policy" target="_blank">Privacy Policy</a> has more details.</span>
      </div>
      <div v-else class="description">
        {{ description }}
      </div>
      <div class="button btn-primary" @click="handleAccept('Accept')">
        {{ $t('featureComponents.page.cookiesAccept.accept') }}
      </div>
    </div>
  </div>
</template>

<script>
// import commonPagesConfig from 'config/pages/common.js';

export default {
  data() {
    return {
      isShow: false,
      description: this.$t('featureComponents.page.cookiesStatement.description'),
    }
  },
  computed: {
    isOrgSchool() {
      const { locale } = this.$store.state;
      return locale === 'am' || locale === 'ae'
    },
  },
  mounted() {
    const cookiesAccept = window.localStorage.getItem('cookiesAccept', 1);
    if (!cookiesAccept) {
      this.isShow = true;
    }
  },
  methods: {
    handleAccept(btnName) {
      this.isShow = false;
      this.cookiesIsAcceptSa(btnName)
      window.localStorage.setItem('cookiesAccept', 1);
    },
    cookiesIsAcceptSa(btnName) {
      this.Sensors.track('bottomsheets_click', {
        button_content: btnName,
      })
    },
  },
};
</script>

<style lang="scss">
.cookies-statement {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 64px;
  background: #f1f1f1;

  .wrapper {
    position: relative;

    &.is-us-cookie {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .description {
    line-height: 64px;
    color: var(--txt-color-lv1);
    font-size: 16px;
  }

  .us-description {
    width: 1100px;
    line-height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
    font-weight: 600;

    a {
      color: var(--txt-color-link);
      text-decoration: underline;
    }
  }

  .button {
    position: absolute;
    right: 0;
    top: 12px;
    width: 120px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
  }
}
</style>
