<template>
  <div class="container container-error">
    <div class="error-wrapper">
      <Exception :tip-text="exceptionTipText" placeholder-class-name="system-error-img" :show-retry-btn="showRetry" :show-back-btn="showBack" @retry="reload" />
    </div>
  </div>
</template>

<script>
import Exception from 'components/common/Exception/index.vue';

export default {
  components: { Exception },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showRetry: false,
      showBack: true,
    }
  },
  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500
    },
    isError() {
      return this.$route.path === '/error';
    },
    exceptionTipText() {
      if (this.statusCode && !this.isError) {
        return `${this.statusCode} - System error. Please try again later.`
      }
      return 'Sorry, a server error occurred.'
    },
  },
  methods: {
    reload() {
      if (this.isError) {
        window.location.href = document.referrer === '' ? window.location.origin : document.referrer;
        return;
      }
      // reload
      window.location.reload();
    },
  },
  head() {
    return {
      htmlAttrs: {
        id: `style__${this.$store.state.betterme}`,
      },
      link: [{
        hid: 'icon',
        rel: 'icon',
        type: 'image/x-icon',
        href: this.$store.state.betterme == 'bettermeedu' ? 'https://download-pa-s3.thethinkacademy.com/static-pa/project/game/assets/favicon.ico' : '/favicon.ico',
      }],
    }
  },
}
</script>

<style scoped lang="scss">
.container-error {
  display: flex;
  height: 500px;
  align-items: center;
}

.error-wrapper {
  width: 100%;
  // flex: 1;
}

.error-img {
  width: 200px;
  height: 200px;
  margin: 0 auto;

  img {
    width: 200px;
    height: 200px;
  }
}

.error-notice {
  text-align: center;
  color: #999;
  font-size: 18px;

  p {
    margin-bottom: 10px;
  }
}

@media screen and (min-height: 800px) {
  .container-error {
    height: 700px;
  }
}
</style>
