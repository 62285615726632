// 页面配置
export default {
  setting: {
    personalInformation: 'Personal Information',
    personalInformationInfo: 'Complete personal information so that teachers can provide better services.',
    myFamily: 'My Family',
    myFamilyInfo: 'Manage my family members',
    myAddress: 'My Addresses',
    myAddressInfo: 'Manage and edit my addresses for delivery and invoice',
    security: 'Login & Security',
    securityInfo: 'Edit my mobile number or email',
    myAccount: 'My Account',
  },
  myAccount: {
    title: 'My Account',
    myAddresses: 'My Addresses',
    personalInformation: 'Personal Information',
    myfamily: 'My Family',
    security: 'Login & Security',
    info: {
      personalInformation: '完善个人信息，以便老师可以更好的提供服务',
      myfamily: '管理我的家庭组成员',
      myAddresses: 'Manage and edit my addresses for delivery and invoice',
      security: 'Edit my mobile number or email',
    },
  },
  common: {
    noResultsFound: 'No Results Found',
    loadMore: 'Load More',
    offlineTip: 'Loading failed, please check your connection.',
    toBeSold: 'To be Sold',
    storeLeft: '{0} Left',
    lineUp: 'Get availability alerts',
    quitLineUp: 'Cancel availability alerts',
    grade: 'Grade',
    subject: 'Subject',
    term: 'Term',
    difficulty: 'Difficulty',
    home: 'Home',
    retry: 'Retry',
    edit: 'Edit',
    done: 'Done',
    copy: 'Copy',
    confirm: 'Confirm',
    cancel: 'Cancel',
    browseAllCourses: 'Browse All Courses',
    browseMoreCourses: 'Browse More Courses',
    filterConfig: [
      {
        placeholder: 'Grade',
        optionList: [],
        selectedId: null,
        idName: 'gradeId',
      }, {
        placeholder: 'Subject',
        optionList: [],
        selectedId: null,
        idName: 'subjectId',
      },
      {
        placeholder: 'Term',
        optionList: [],
        selectedId: null,
        idName: 'termId',
      }, {
        placeholder: 'Difficulty',
        optionList: [],
        selectedId: null,
        idName: 'levelDegreeId',
      },
    ],
  },
  courseList: {
    title: 'My Courses',
    orderFilters: [
      {
        value: 'ALL',
        label: 'All',
      },
      {
        value: 'CURRENT',
        label: 'Current',
      },
      {
        value: 'COMPLETED',
        label: 'Completed',
      },
    ],
    noCoursesTip: 'No courses under this category. Browse more courses provided by Think Academy.',
    categoryNoCourse: 'No courses under this category. <br/> Select another grade and find more courses provided by Think Academy.',
    cannotFind: 'Can\'t find the course of your children here?',
    clickHere: 'Click here',
    cannotFindTip: ' to select a student and switch it to the current student account, and then refresh this page to view.',
    inSession: 'Class In Session',
    enter: 'Enter ',
    nextLesson: 'Next Lesson',
    lessonFirst: 'Lesson 1',
    completed: 'Completed',
    refunded: 'Order Refunded',
    playback: 'Playback Only',
  },
  courseDetail: {
    breadcrumbConfig: {
      categoryName: 'My Courses',
      title: 'Course Detail',
    },
    noticeTip: 'Is the class time/difficulty not a good fit? Apply for a ',
    noticeTransfer: 'class reschedule/transfer',
    transferred: 'Transferred',
    classroom: 'Classroom',
    howToAttend: 'How to attend',
    unpaid: 'Unpaid',
  },
  attendClassNotice: {
    howToAttendClass: 'How to attend class',
    openSoftware: 'Open Software',
    downloadSoftware: 'Download Software',
    attendContent: 'Please download Think Academy Student software to attend live classes.',
    attendTip: 'To ensure a better learning experience, please download Think Academy software to start the course.',
    dualAttend: 'Please attend the class using iPad app',
    dualCall: 'If you have any questions, please call 844-844-6587.',
    zoomDownload: 'Download',
    zoomID: 'Zoom ID',
    zoomPassword: 'Zoom Password',
    zoomInfo: 'and enter the Zoom ID and password as above.',
    location: 'Location',
    venuePhone: 'Phone',
    classinLink: 'Classin Link',
    thinkLiveLink: 'Think Live Link',
    onlineTip: 'Please attend the class using iPad App Thinkhub. If you have any questions, please call 844-844-6587.',
    tipMap: {
      ZOOM: {
        url: 'https://zoom.us/download',
        desc: 'and enter the Zoom ID and password as above. Enter into the classroom 10 minutes before the live session on Zoom and enjoy your Think Academy experience.',
        key: 'zoom',
        downloadDesc: 'Download Zoom',
      },
      CLASS_IN: {
        url: 'https://www.classin.com/en/m/download.html',
        key: 'classIn',
        desc: 'and register with the phone number you used when you enrolled into the course.',
        downloadDesc: 'Download Classin',
      },
      OFFLINE: { key: 'offline', desc: 'Please arrive at the classroom 10 minutes before and enjoy your Think Academy experience.' },
      BIG_ONLINE: { key: 'online', desc: '为保证上课效果，请打开Think Academy App进入课堂' },
      DUAL: { key: 'offline', desc: 'Please arrive at the classroom 10 minutes before the class and enjoy your Think Academy experience.' },
      ONLINE: { key: 'online' },
      THINK_LIVE: { key: 'thinkLive' },
    },
  },
  classTransfer: {
    title: 'Class Reschedule/Transfer',
    transfer: 'Transfer',
    rule: 'The reschedule/transfer rules',
    taking: 'Your child is currently taking the follow courses:',
    changeClass: 'Change Class',
    noCoursesTip: 'No available course for reschedule/transfer',
    rescheduleSteps: [{
      title: 'Select a Lesson to Reschedule',
    }, {
      title: 'Select a Lesson to Switch Into',
    }, {
      title: 'Confirm Reschedule Details',
    }, {
      title: 'Reschedule Results',
    }],
    transferSteps: [{
      title: 'Select a Class to Transfer to',
    }, {
      title: 'Confirm Class Transfer',
    }, {
      title: 'Reschedule Results',
    }],
    selectLessonTips: `
      <p>(A total of {0} reschedule opportunities, with <span style="color: #ffaa0a">{1}</span> remaining)</p>`,
    selectLessonTipsPreface: ' <p>The following lessons have yet to start, you can click a \'Class Card\' to reschedule the lesson.</p> ',
    confirmReschedule: `<p>After clicking "Confirm", a course reschedule opportunity will be used.</p>
      <p>(A total of {0} reschedule opportunities, with <span style="color: #ffaa0a">{1}</span> remaining)</p>`,
    confirmRescheduleTip: `
      <p>Note: After the class is rescheduled, the {0} will change.</p>
      <p>After the reschedule completes, please follow the lesson details inside "My Courses" to attend the class on time.</p> `,
    rescheduleChanges: {
      difficulty: 'difficulty',
      classTime: 'class time',
      teacher: 'teacher',
    },
    resultOptions: {
      0: {
        title: 'Transfer Failed',
        describe: 'The lesson to be transferred to/from is in session(30 minutes before the session to 30 minutes after the session). Class reschedule is currently unavailable.',
        btnText: 'View Class Details',
      },
      1: {
        title: 'Change Successful',
        describe: 'Please go to the "My Courses" to check the updated class details',
        btnText: 'View Class Details',
      },
    },
    transferResultOptions: {
      0: { title: 'Transfer Failed', describe: 'An error occurred, please try again later', btnText: 'Back' },
      1: { title: 'Change Successful', describe: 'Please go to the "My Courses" to check the updated class details', btnText: 'View Class Details' },
    },
    entranceTip: {
      text: 'Is the class time/difficulty not a good fit? Apply for a ',
      btn: 'class reschedule/transfer',
    },
    noRescheduleLesson: 'There are currently no lessons you can reschedule to.</br> If you need to reschedule this class, please contact your teacher.',
    transferTipTop: 'Note: After transferring to a different class, {0} will change.',
    transferTipBottom: 'After the transfer completes, please follow the lesson details inside "My Courses" to attend the class on time.',
    transferOption: {
      grade: 'the grade',
      difficulty: 'difficulty',
      classTime: 'class time',
      teachers: 'teacher',
    },
    samePriceTip: 'After clicking ‘Confirm’ a course reschedule opportunity will be used.',
    refundTip: 'After clicking ‘Confirm’ , you will be refunded with the difference in class price.',
    refundConfirm: 'After confirming, one chance will be used',
    supplementaryPaymentTip: 'After clicking ‘Confirm’, you will need to pay the transfer fee.',
    supplementaryPaymentConfirm: 'Once this has been paid, one chance will be used',
    remainingTimes: '({0} times in total, {1} times left)',
    finalResultOption: {
      // 转班成功
      success: { title: 'Change Successful', describe: 'Please go to the "My Courses" to check the updated class details', btnText: 'View Class Details' },
      // 普通转班失败
      fail: { title: 'Transfer Failed', describe: 'An error occurred, please try again later', btnText: 'Back' },
      // 付款失败
      toBePaid: {
        title: 'Paid failed',
        describe: 'Please complete the payment in {remainTime}，or the class transfer will be cancelled',
        btnText: 'Pay Now',
        cancel: 'Cancel the transfer',
      },
    },
  },
  orderAcknowledgement: {
    classTitle: 'Transfer class details',
    classlassRefund: {
      targetFee: 'Target class price',
      currentFee: 'Current class price',
      spread: '{0} (need to refund)',
      installmentNote: 'Note: The payment method for this class is by instalment, so only the difference in price of the purchased lessons will be refunded for the unstarted lessons.',
    },
    classSupplementaryPayment: {
      targetFee: 'Target class price',
      currentFee: 'Current class price',
      spread: '{0} (need to pay)',
      // eslint-disable-next-line max-len
      installmentNote: 'Note: The payment method for this class is by instalments, you only need to pay the difference in price for the lessons which are not started in the purchased instalment; the unpurchased lessons will be paid in the subsequent instalment plan.',
    },
    summaryTitle: 'Order Summary',
    summaryRefund: {
      targetFee: 'Course fee (target class)',
      currentFee: 'Remaining course fee (current class)',
      coupons: 'coupons',
      subTotal: 'Subtotal',
      refundAmount: 'Refund Total',
    },
    summarySupplementaryPayment: {
      targetFee: 'Course fee (target class)',
      currentFee: 'Remaining course fee (current class)',
      subTotal: 'Subtotal',
      tax: 'tax',
      total: 'Order Total',
    },
    btnRefund: 'Confirm',
    btnSupplementaryPayment: 'Checkout',
  },
  unpaidTransfer: {
    continueConfig: {
      title: 'Are you sure to continue?',
      content: 'After swtiching to a new class, your spot in the previous class will be removed.',
      mainBtn: 'Yes',
      subBtn: 'Cancel',
    },
    resultOptions: {
      0: {
        title: 'Transfer Failed',
        describe: 'Error. Please select another class. ',
        odditionalBtnText: 'Back to Unpaid Courses',
        btnText: 'View Class Details',
        showOdditionalBtn: true,
        showBtn: false,
      },
      1: {
        title: 'Change Successful',
        describe: 'Now you can pay for this class in class details or unpaid courses to finish registeration.',
        btnText: 'View Class Details',
        showOdditionalBtn: true,
        odditionalBtnText: 'Back to Unpaid Courses',
      },
    },
    conflictTitle: 'There is time conflict(s) with your current class below.',
    availableClasses: 'Available Classes',
    renewalTip: 'Please select the class you want before the deadline:',
    paymentPrompt: 'This class currently has a transfer order pending payment because of the difference in price, no further transfers can be made at this time.',
  },
  classCard: {
    difficulty: 'Difficulty',
    liveClass: 'Live Class',
    inPerson: 'In Person',
    registerStatus: {
      registered: 'Registered',
      register: 'Register',
      full: 'Full',
      unavailable: 'Unavailable',
      enrolNow: 'Enroll',
      viewPhasedPlan: 'Installment Plan',
      renewal: 'Pay Installment',
    },
  },
  changeClass: {
    select: 'Select Change Type',
    reschedule: 'Reschedule a Lesson',
    rescheduleDesc: 'If you would like to stay in the current class and only change a lesson time, you can choose to reschedule a lesson',
    transfer: 'Transfer Remaining Lessons',
    transferDesc: 'If you would like to change the time, teacher, or difficulty of ALL the remaining lessons, you can apply to transfer to a different course',
    changeMsg1852: 'You have requested a refund for this course, the reschedule function is temporarily unavailable',
    changeMsg1852Transfer: 'You have requested a refund for this course, the transfer function is temporarily unavailable',
    changeMsg1853: 'This class is currently in session, the reschedule function is temporarily unavailable',
    changeMsg1586: 'All lessons you purchased for this class have already started, the reschedule function is temporarily unavailable',
    changeMsg1586Transfer: 'All lessons you purchased for this class have already started, the transfer function is temporarily unavailable',
    changeMsgReschedule: 'You have a total of {0} rescheduled opportunities, with {1} remaining',
    changeMsgTransfer: 'You have a total of {0} transfer opportunities, with {1} remaining',
    changeClassToPay:
    {
      title: 'Payment Reminder',
      content: 'Sorry, you are unable to change to this course. To change the course, please complete the upcoming instalment payment first.',
      mainBtn: 'Proceed to Payment',
      subBtn: 'Cancel',
    },
  },
  classTransferResult: {
    case611: {
      title: 'Automatic deduction authorization failed',
      content: 'Please enter unpaid courses to re authorize automatic deduction',
      mainBtnText: 'To set',
    },
  },
  testResults: {
    title: 'Test Results',
    redeemDesc: 'If you have a Think Academy registration qualification code, please ',
    redeemHere: 'redeem here',
    noResult: 'No test results yet.',
    check: 'Check available classes',
  },
  orderList: {
    title: 'My Orders',
    orderDetail: 'Order Detail',
    changeAddress: 'Change Address',
    productType: 'Product Type',
    orderStatusList: [
      {
        value: '',
        label: 'All',
      },
      {
        value: '200',
        label: 'Paid',
      },
      {
        value: '100',
        label: 'To be paid',
      },
      {
        value: '300',
        label: 'Cancelled',
      },
      {
        value: '400',
        label: 'Refunded',
      },
    ],
    noOrders: 'No orders under this category. Browse more courses provided by Think Academy.',
    moreCourses: 'Browse More Courses',
  },
  orderCard: {
    completePayment: 'Please complete the payment in',
    orderTime: 'Order Time',
    stageTag: 'Installment order',
    teacher: 'Teacher',
    payNow: 'Pay Now',
    browseCourses: 'Browse Courses',
    viewOrder: 'View Order',
    viewCourse: 'View Course',
    browseOtherCourses: 'Browse Other Courses',
  },
  couponList: {
    redeemHere1: 'If you have a promo code, please ',
    redeemHere2: 'Redeem it here',
    redeemHere3: 'If you have a promo code, please redeem it here',
    redeemPromo: 'Redeem Promo Code',
    selectStudents: 'Select students who need to redeem coupon codes',
    redeemLnb1: 'If you want to redeem a promo code for a new student, please go to ',
    redeemLnb2: 'Profile',
    redeemLnb3: ' to create a new student',
    title: 'My Coupons',
    detailTip: 'This coupon can be applied to the following courses',
    couponPolicy: 'Coupon Policy',
    history: 'History',
    noCoupon: 'No Available Coupon',
    promoCode: 'Enter a promo code',
    apply: 'Apply',
    policyInfo: [
      `Only 1 coupon of the same category can be used for each order. Tuition vouchers can only be used to deduct tuition fees, and teaching materials vouchers can only be used to deduct teaching materials. 
      Coupons of different categories can be used at the same time.`,
      'Each coupon can only be applied to specific courses in coupon details.',
      'Coupon will no longer be available after expiration date.',
      `Coupon Return Policy: Coupon will be returned to customers if refund is requested before the first class (or after trial class, if applicable). 
      Coupons will not be returned for refund request after the start of the first class.`,
      'Coupons cannot be exchanged for cash at all time, and is for one time use only.',
      'For more information, please contact Think Academy customer service at: <p style="color:#222222;display:inline-block">Customercare@thinkacademy.sg</p>.',
    ],
    historyFilters: [
      {
        value: 1,
        label: 'Redeemed',
      },
      {
        value: 2,
        label: 'Expired',
      },
    ],
    redeem: 'Redeem',
    details: 'Details',
    cannotUseReason: {
      1: 'Only for selected courses',
      2: 'On orders over',
      3: 'Available since',
      4: 'Cannot be used together with the chosen coupon.',
      7: 'Only applicable to textbook fee.',
      8: 'Selected coupons have reached the max limit of coupon savings. Cannot be used together with the chosen coupon.',
    },
    selectCoupons: 'Select Coupons',
    couponTip: 'The discount will be apportioned to each of your installments or fully deducted in the current period based on the attributes of the voucher. Please refer to the final payment amount.',
    couponTipBtn: 'View voucher details>>',
    tipPopoverTitle: 'Voucher Details',
    bestSavings: 'Use best savings',
    couponsSelectedNum: '1 Coupon | {count} Coupons',
    couponsAvailable: 'Coupons Available',
    noAvailable: 'No Available Coupons',
    newPromo: 'Do you have a new promo code to redeem?',
    canUseCount: '1 Coupon Available | {count} Coupons Available',
    timeStatusClassMap: {
      1: 'available-soon',
      2: 'new',
      3: 'expire-soon',
    },
    timeStatusTextMap: {
      1: 'Available Soon',
      2: 'NEW',
      3: 'Expire Soon',
    },
    register: 'Register',
    unavailable: 'Unavailable',
    registered: 'Registered',
  },
  unpaidCourses: {
    title: 'Unpaid Courses',
    guide: 'Unpaid Courses Guide',
    switch: 'Switch',
    free: 'Free',
    noCoursesTip: 'There are no unpaid courses at the moment.',
    renewTime: 'Next installment due',
    expired: 'Expired',
    noAvailableClass: 'There is no available class.',
    phasedPlanTitle: 'Installment Plan',

  },
  unpaidDialog: {
    title: 'No more headaches to choose！',
    visit1: 'Our recommended courses are now available in the ',
    visit2: 'cart!  Welcome to speak to our dedicated teacher to understand more.',
    contact: '',
    see: 'CHECK IT OUT',
    portal: '',
  },
  paymentRemindDialog: {
    title: 'Payment Reminder',
    visit1: 'The next instalment payment for your child\'s ongoing course can now be paid. For a smooth learning experience, please complete payment as soon as possible.',
    visit2: '',
    contact: '',
    see: 'Proceed to Payment',
    portal: '',
  },
  wishList: {
    title: 'Wish List',
    remove: 'Remove',
    selectAll: 'Select all',
    noCoursesTip: 'Your wish list is empty. You can add courses into your wish list when browsing course details.',
  },
  personalInfo: {
    current: 'Current',
    title: 'Personal Infomation',
    displayName: 'Display name',
    firstName: 'First Name',
    lastName: 'Last Name',
    chooseGrade: 'Choose Your Grade',
    email: 'Email',
    addEmail: 'Add E-Mail Address',
    save: 'Save',
    edit: 'Edit',
    reselect: 'Reselect',
    nameTip: 'Username will be used during class, comments, etc.',
    editUserName: 'Edit Username',
    editFirstName: 'Edit First Name',
    editLastName: 'Edit Last Name',
    invalid: 'This input is invalid.',
    invalidEmail: 'This input is invalid.',
    maximun: 'Maximun 65 letters. Letters and numbers supported.',
    maximun32: 'Maximun 32 letters. Letters supported.',
    signOut: 'Sign Out',
    studentInformation: 'Student Information',
    accountSettings: 'Account Settings',
    loggedInWith: 'You are logged in with',
    myFamily: 'My Family',
    otherStudents: 'Other students in my family',
    addAnother: 'Add Another Student',
    addTip1: '*If you want to sign up for more than one child, please click ',
    addTip2: 'to add a new student account.',
    maximumAdd: 'A maximum of 6 students can be associated.',
    addAccount: 'Add a New Account',
    enter: 'Enter ',
    pleaseSelect: 'Please select',
    pattern: {
      nickname: /^[a-zA-Z0-9\u4e00-\u9fff\s]{1,65}$/,
      name: /^[a-zA-Z\s]{0,32}$/,
    },
    switch: 'Switch',
    studentCancel: {
      1: {
        title: 'You can\'t do this right now',
        content: 'This student is actively enrolled in a course.',
        mainBtnText: 'Cancel',
      },
      2: {
        title: 'You\'re about to remove this student',
        content: 'After removing this student, use these details to log in:',
        subBtnText: 'Cancel',
        mainBtnText: 'Confirm',
        phone: 'Mobile number',
        email: 'Email Address',
      },
      3: {
        title: 'You\'re about to remove this student',
        content: 'This student isn\'t registered to an email address or mobile number. You cannot access their account after removing them. ',
        subBtnText: 'Cancel',
        mainBtnText: 'Confirm',
      },
      4: {
        title: 'You can\'t do this right now',
        content: 'You can\'t remove this account right now, because it\'s a registration account',
        subBtnText: '',
        mainBtnText: 'Got it',
      },
    },
  },
  logistics: {
    title: 'Shipment Tracking',
    package: 'Package',
    carrier: 'Carrier',
    trackingNumber: 'Tracking Number',
    shipmentTracking: 'Shipment Tracking',
    items: 'Items',
    deliveryAddress: 'Delivery Address',
  },
  payment: {
    tips: 'Installment payment requires you to authorize the auto-debit function(only stipe payment method ). Please note that the card has sufficient funds to avoid the seat being removed due to failure to pay on time.',
  },
};
