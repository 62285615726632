<template>
  <div class="no-avalible-data-wrapper">
    <div class="placeholder-img-wrapper" :class="[placeholderClassName]" v-lazy-background :lazy-background="placeholderBg" />
    <div class="tip-text text-describe" v-html="tipText" />
    <div v-if="showRetryBtn" class="operate-container">
      <div class="button button-small" :class="className" @click="retry" v-html="retryBtnText" />
    </div>
    <div v-if="showBackBtn" class="operate-container">
      <div class="button button-small btn-yellow back-btn" @click="backToHome" v-html="backBtnText" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholderClassName: {
      default: '',
      type: String,
    },
    tipText: {
      default: 'No Available Data',
      type: String,
    },
    showRetryBtn: {
      default: false,
      type: Boolean,
    },
    showBackBtn: {
      default: false,
      type: Boolean,
    },
    retryBtnText: {
      default: 'Retry',
      type: String,
    },
    backBtnText: {
      default: 'Back to Home',
      type: String,
    },
    className: {
      default: 'button-grey',
      type: String,
    },
  },
  computed: {
    placeholderBg() {
      if (this.placeholderClassName === 'no-avalible-data-img') {
        // eslint-disable-next-line global-require
        return require('assets/images/error/no-available-data.png');
      }
      if (this.placeholderClassName === 'system-error-img') {
        // eslint-disable-next-line global-require
        return require('assets/images/error/bg-error.png');
      }
      return '';
    },
  },
  methods: {
    retry() {
      this.$emit('retry')
    },
    backToHome() {
      window.location.href = '/'
    },
  },
}
</script>

<style lang="scss" scoped>
.no-avalible-data-wrapper {
  width: 100%;

  .placeholder-img-wrapper {
    margin: 0 auto;
    width: 225px;
    height: 225px;

    &.no-avalible-data-img {
      // background: url('~assets/images/error/no-available-data.png') center no-repeat;
      background-size: 100%;
    }

    &.system-error-img {
      // background: url('~assets/images/error/bg-error.png') center no-repeat;
      background-size: 100%;
    }
  }

  .placeholder-img {
    width: 100%;
    height: 100%;
  }

  .tip-text {
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: var(--txt-color-lv2);
  }

  .operate-container {
    margin-top: 20px;
    text-align: center;

    .button {
      display: inline-block;
      margin: 0 auto;
    }
    .back-btn {
      font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
    }
  }
}
</style>
